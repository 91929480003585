<template>
  <v-footer>
    <v-container class="py-2">

      <!---
      <v-row>
        <v-col cols="12" md="12">
          <div class="text-h6 text-lg-h6 font-weight-bold">{{ footerMessage }}</div>
          <div style="" class="mb-2 mt-1 secondary" />
          <div class="d-flex mb-1 text-md-body-1">
            <v-icon color="secondary lighten-1" class="mr-2">mdi-phone-outline</v-icon>
            <a href="#" class="text-decoration-none secondary--text text--lighten-2">{{ footerPhone }}</a>
          </div>
          <div class="d-flex mb-1 text-md-body-1">
            <v-icon color="secondary lighten-1" class="mr-2">mdi-email-outline</v-icon>
            <a href="#" class="text-decoration-none secondary--text text--lighten-2">{{ footerEmail }}</a>
          </div>
        </v-col>
      </v-row>
      <v-divider class="my-3"></v-divider>
      --->
      <v-row>
        <v-col cols="2" class="pa-0 ma-0"/>
        <v-col cols="4" class="pa-0 ma-0 mt-2 d-flex text-center">
          <v-img
            max-height="115"
            contain
            src="http://www.offertravelprotection.com/wp-content/uploads/2017/12/rental-guardian.png"
            alt="Rental Guardian"
            title="Rental Guardian"
            @click="openRG"
          ></v-img>
        </v-col>
        <v-col cols="4" class="pa-0 ma-0 mt-2 d-flex text-center">
          <v-img
            max-height="115"
            contain
            src="http://www.offertravelprotection.com/wp-content/uploads/2017/12/insurance-stays.png"
            alt="Insurance Stays"
            title="Insurance Stays"
            @click="openInsure"
          ></v-img>
        </v-col>
        <v-col cols="2" class="pa-0 ma-0"/>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-0 mt-6 mb-0 text-center text-body-2">
          <span class="blue--text text--darken-4">Copyright © Rental Guardian {{ new Date().getFullYear() }}. All Rights Reserved.</span>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" class="pa-0 ma-0"/>
        <v-col cols="8" class="pa-0 mt-3 mb-4 text-center text-caption">
          <span class="blue-grey--text text--darken-4">
            Powered by the RentalGuardian.com platform.<br>
            Products offered by InsureStays<br>
            Underwritten by US Fire and Nationwide Mutual Insurance Companies and Affiliated Companies.
          </span>
        </v-col>
        <v-col cols="2" class="pa-0 ma-0"/>
      </v-row>
      <v-row>
        <v-col cols="12" class="pa-0 mt-3 mb-0 text-center text-caption blue-grey--text text--darken-2">
          <p>InsureStays is a dba of Sandhills Insurance Group, LLC, a limited liability company organized under the laws of South Carolina, a licensed insurance producer in all states (NPN 16269113, California License No. 0M14453, TX License No. 2194106 and 2205876); dba in California InsureStays Insurance Agency; dba in Florida InsureStays Agency. RentalGuardian is a unique online software service provider, specializing in the needs of property managers, with state-of-the-art features including fully integrated, scalable API; multi-underwriter support tools; unique online claim wizard for simplified claims administration; and superior document delivery and administrative functionality. Play Travel Protection is underwritten by US Fire and Nationwide Mutual Insurance Companies and Affiliated Companies.</p>
          <p>This retailer is an authorized travel retailer and referral provider of Sandhills Insurance Group LLC, dba in California InsureStays Insurance Agency and dba in various states InsureStays and InsureStays Agency; mailing address is PO Box 4443, Pawleys Island, SC 29585. Phone is (843) 286-5144. Visit www.insurestays.com for more information.</p>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>
<!--- 

https://www.rentalguardian.com/
https://insurestays.com/

--->
<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState ({
      meta: (state) => state.microsite.meta
    }),
    footerPhoneMsg() {
      return this.meta.custom_phone_msg
    },
    footerPhone() {
      return this.meta.custom_phone
    },
    footerMessage() {
      return this.meta.attributes.FooterMessage
    },
    footerEmail() {
      return this.meta.custom_email
    }
  },
  methods: {
    openRG() {
      window.open('https://www.rentalguardian.com/', 'rg')
    },
    openInsure() {
      window.open('https://insurestays.com/', 'insure')
    }
  }
}

</script>
